import { React, useState } from 'react';
import { useNavigate } from "react-router-dom";
import axios from 'axios';
import Swal from 'sweetalert2';
import { MdLockOutline } from "react-icons/md";
import { HiOutlineMail } from "react-icons/hi";

const baseUrl = process.env.REACT_APP_BASE_URL;
const loginEndPoint = process.env.REACT_APP_DESIGNER_LOGIN;


const Login = () => {

  const navigate = useNavigate();
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [name, setName] = useState('')
  const [emailError, setEmailError] = useState(null);
  const [passwordError, setpasswordError] = useState(null);


  const config = {
    headers: {
      'Content-Type': 'application/json',
    }
  }

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!email) {
      setEmailError('email is require!.');
      return;
    }
    if (!password) {
      setpasswordError('password is require!.');
      return;
    }
    if (!validateEmail(email)) {
      setEmailError('Invalid email address.');
      return;
    }
    if (!ValidPassword(password)) {
      setpasswordError('Password should contain 8 characters!');
      return;
    }

    await handleChange()

  }

  const validateEmail = (email) => {
    const regex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return regex.test(email);
  }
  const ValidPassword = (password) => {
    const regex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/;
    return regex.test(password);
  }
  const handleChange = async () => {
    const data = {
      email: email,
      password: password,
    };
    try {
      const result = await axios.post(`${baseUrl}/${loginEndPoint}`, JSON.stringify(data), config);
      if (result.data.status === true) {
        localStorage.setItem('designer_email', JSON.stringify({ email: result.data.data.email }));
        localStorage.setItem('token', result.data.data.token);
        Swal.fire({
          title: 'Login Successful!',
          confirmButtonText: 'Ok'
        }).then((result) => {
          if (result.isConfirmed) {

            navigate("/Slot");
            window.location.reload()
          }
        });
      }
    } catch (error) {

      console.error("Error fetching data:", error);

      Swal.fire({
        title: 'Login Failed!',
        text: 'An error occurred during login. Please try again.',
        icon: 'error',
        confirmButtonText: 'Ok'
      });
    }
  };




  return (
    <div className='container-fluid p-5 sectionLogin'>
      <div className='row d-flex justify-content-center mt-5 login-card'>
        <div className="col-lg-6 col-md-12 col-sm-12 pt-3 login-card-col">
          {/* <img className="img-fluid px-5" width="60%" loading="lazy" src={Logo} alt="Logo" /> */}
        </div>
        <div className='col-lg-6 col-md-8 col-sm-4 login-form'>
          <form onSubmit={handleSubmit}>
            <div className="row d-flex justify-content-center p-5  ">
              <h3 className='login-head text-center mb-5'>Login</h3>

              <div className="form-group row mt-3">
                <div className="col-sm-12">
                  <label htmlFor="email" className="form-label">Email</label>
                  <input type="email" className="form-control form-control_login" name="email" id="email" value={email} onChange={(e) => setEmail(e.target.value)} placeholder="name@example.com" required />
                  {emailError && <div className="text-end text-danger">{emailError}</div>}
                </div>
              </div>
              <div className="form-group row mt-3">
                <div className="col-sm-12">
                  <label htmlFor="password" className="form-label">Password</label>
                  <input type="password" className="form-control form-control_login" name="password" id="password" value={password} onChange={(e) => setPassword(e.target.value)} required />
                  {passwordError && <div className="text-end text-danger">{passwordError}</div>}
                </div>
              </div>
              <div className="form-group mt-3 d-flex justify-content-center">
                <button className="btn login-btn mt-5" type="submit" onClick={handleSubmit} >Login</button>
              </div>
              {/* {serverError && <div className="text-end text-danger">{serverError}</div>} */}

            </div>
          </form>
        </div>
      </div>

      {/* <div className='row footer'>
          <Footer />
        </div> */}
    </div>
    // <section className="vh-100  login">
    //   <div className="container-fluid ">
    //     <div className="row">
    //       <div className='d-flex justify-content-center'></div>

    //       <div className="form-col-right">
    //         <div className="">

    //           <form className='mt-5' onSubmit={(e) => handleSubmit(e)}   >
    //             <div>
    //               <h1 className='welcome align-center mt-5'><b>WelCome</b></h1>
    //               <p style={{ textAlign: "center", fontWeight: "900" }}>login with Email</p>
    //             </div>

    //             <fieldset className="border mb-">
    //               <legend className="float-none w-auto email-input">Email Id:</legend>
    //               <div className='icon-input-dv'>
    //                 <MdLockOutline style={{ color: "white", fontSize: "1.5em" }} />
    //                 <input type="text"
    //                   className='email'
    //                   value={email}
    //                   onChange={(e) => setEmail(e.target.value)}
    //                   placeholder='example@gmail.com..' />

    //               </div>
    //             </fieldset>

    //             <fieldset className="border mb-3">
    //               <legend className="float-none w-auto email-input" >Password:</legend>
    //               <div className='icon-input-dv'>
    //                 <HiOutlineMail style={{ color: "white", fontSize: "1.5em" }} />

    //                 <input type="text"
    //                   className='email'
    //                   value={password}
    //                   onChange={(e) => setPassword(e.target.value)}
    //                   placeholder='*********' />
    //               </div>
    //             </fieldset>

    //             <div className='d-flex align-items-center'></div>
    //             {emailError && <h2 style={{ color: 'red', fontSize: "15px" }}>{emailError}</h2>}
    //             {passwordError && <h2 style={{ color: 'red', fontSize: "15px" }}>{passwordError}</h2>}


    //             <p className='mb-5 forgot-pass'><a href='#'>Forgot your password?</a></p>
    //             <div className='d-flex jusify-content-center'>
    //               <input className="btn btn-primary submit-btn"
    //                 type="submit"
    //                 onClick={(e) => handleSubmit(e)}
    //                 value="Login" />

    //             </div>


    //           </form>

    //         </div>
    //         <div className='d-flex justify-content-center footer '>© 2023 xwebinfolab. All rights reserved.</div>
    //       </div>

    //     </div>
    //   </div>
    // </section>

  )
};

export default Login;



import React, { useState, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import axios from 'axios';
import Swal from 'sweetalert2';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import header from '../../images/header_aaa.png';
import moment from 'moment';

const baseurl = process.env.REACT_APP_BASE_URL;
const adsEndpoint = process.env.REACT_APP_FIND_ADS_ENDPOINT;

const uploadimageEndpoint = process.env.REACT_APP_BOOKED_ADS_UPLOAD_IMG_ENDPOINT
const payment_key = process.env.REACT_APP_KEY_ID;
const imageurl = "https://qgbimages.s3.ap-south-1.amazonaws.com/AdsImage"
const cityId = localStorage.getItem('selectedCity');

const Page1 = () => {
    const Location = useLocation();
    const navigate = useNavigate();

    const selectedDate = Location.state && Location.state.selectedDate;
    const slotId = Location.state && Location.state.slotId;

    const formattedDate = selectedDate ? moment(selectedDate).format('YYYY-MM-DD') : null;
    const formattedDateForPage = selectedDate ? moment(selectedDate).format('DD-MM-YYYY') : null;
    const [orderId, setOrderId] = useState('');
    if (formattedDate == null) {
        navigate('/admin/BookedAds')
    }
    const [open_add_slotModal, setopen_add_slotModal] = useState(false);
    const [selectedImage, setselectedImage] = useState(null);
    const [selectedSlotId, setSelectedSlotId] = useState(null);
    const [error, setError] = useState('');
   

    const [data, setData] = useState([]);
  
    useEffect(() => {
        const fetchData = async () => {
            try {
                const data = {
                    page_id: "65eec32267ca18842130d746",
                    slot_Date: formattedDate
                }
                const url = `${baseurl}/${adsEndpoint}`;
                const response = await axios.post(url, data);
                // console.log(response.data.data)
                setData(response.data.data);
            } catch (error) {
                console.error('Error fetching data:', error);
            }
        };

        fetchData();
    }, []);

   

    const closeModal = () => {
        setopen_add_slotModal(false);
    };
    const openModal = (booked_id) => {
        setSelectedSlotId(booked_id)
        setopen_add_slotModal(true);
    };

    const handleUploadChange = (e) => {

        setselectedImage(e.target.files[0]);
        setError('');
    };

    const handleUploadImage = async (e) => {
        e.preventDefault();
        try {
            const formData = new FormData();
            formData.append('image', selectedImage);
            formData.append('_id', selectedSlotId);
            const response = await axios.put(`${baseurl}/${uploadimageEndpoint}`, formData, {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            });
            if (response.data.status == true) {
                Swal.fire({
                    icon: 'success',
                    title: 'Success',
                    text: 'image upload successful',
                    // showCancelButton: true,
                    confirmButtonText: 'ok',
                }).then((result) => {
                    if (result.isConfirmed) {
                        window.location.reload();
                    }
                });

            }
        } catch (error) {
            console.error('Error uploading image:', error);

        }
    };
    return (
        <div className="container pt-5" >
            <h2 className="headings">Selected Date:{formattedDateForPage}</h2>

            <div className="row d-flex justify-content-center m-2">
                <div className="col-sm-6 col-md-6 page1 bg-light" style={{ border: '1px solid black' }}>
                    <div className="container p-1" >

                        <div className="row">
                            <img src={header} className="heading-image1" />
                            {data.map((ad, index) => (
                                <>
                                    {ad.image ? (
                                        <>
                                            {/* <div key={index}> */}
                                            <img src={`${imageurl}/${ad.image}`} width="100%" alt="Ads" className={`col-md-12  text-center mb-1 ads${index + 1}_For_page1 adsImage`}
                                             />
                                            {/* </div> */}
                                        </>
                                    ) : (
                                        <>
                                            <div key={index} className={`col-md-12 text-center ads${index + 1}_For_page1 page ${ad.Is_booked ? "bg-danger" : "notBooked"}`}>
                                                {ad.image ? (
                                                    <></>
                                                ) : (
                                                    <>
                                                        <p>PageSize:- {ad.pageSize}</p>
                                                        <p>Price:- {ad.price}</p>
                                                        {ad.Is_booked && <p><b>This ad is booked.</b></p>}
                                                    </>
                                                )}
                                            </div>
                                        </>
                                    )}
                                </>



                            ))
                            }
                            {/* {data.map((ads, index) => (
                                <div key={ads.id} className={`col-md-12 mt-1 text-center ads${index + 1}_For_page1 page ${ads.Is_booked ? "bg-danger" : "notBooked"}`}
                                    style={{
                                        backgroundImage: ads.image ? `url(${imageurl}/${ads.image})` : 'none',
                                        backgroundSize: 'cover',
                                        backgroundRepeat: 'no-repeat',
                                        backgroundPosition: 'center'
                                    }}  >
                                    {ads.image ? (
                                        <></>
                                    ) : (
                                        <>
                                            <p>PageSize:- {ads.pageSize}</p>
                                            <p>Price:- {ads.price}</p>
                                            {ads.Is_booked && <p><b>This ad is booked.</b></p>}
                                            {!ads.Is_booked && <button className="allpage_book_btn" onClick={() => handleBookAds(ads._id)}>Book Ads</button>}
                                        </>
                                    )}
                                </div>
                            ))} */}
                        </div>
                    </div>
                </div>
            </div>

            <Modal show={open_add_slotModal} onHide={closeModal} dialogClassName="modal-responsive">
                <Modal.Header closeButton>
                    <Modal.Title>Add Slot</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <form onSubmit={handleUploadImage}>
                        <div className="form-group">
                            <label>upload file:</label>
                            <input
                                type="file"
                                className="form"
                                onChange={handleUploadChange}
                            />
                        </div>
                        {error && <div className="alert alert-danger">{error}</div>}
                        <button type="submit" className="btn btn-primary bg-primary" onClick={handleUploadImage}>Submit</button>
                    </form>
                </Modal.Body>
            </Modal>
        </div>
    );
}

export default Page1;

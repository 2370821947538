import React from "react";
import { useLocation, useNavigate } from "react-router-dom";



const Header = () => {
    const navigate = useNavigate()

    const handleLogout = () => {
        localStorage.removeItem('token');
        navigate("/")
    };
  
    return (
        <>
            <div className="container mt-3">
                <div className="row">
                    <div className="col-lg-9 col-md-10 col-sm-6">
                     
                    </div>
                    <div className="col-lg-3 col-md-2 col-sm-6 text-end">
                        <button className="btn login-btn" style={{border:'2px solid yellow'}} onClick={handleLogout} >Log Out</button>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Header
import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import moment from 'moment';
import Dropdown from 'react-bootstrap/Dropdown';

const baseurl = process.env.REACT_APP_BASE_URL
const slotEndpoint = process.env.REACT_APP_SLOT_LIST_ENDPOINT;


const Slot = () => {
    const navigate = useNavigate();

    const handlePageOpen = (date, _id) => {
        navigate("/select-page", { state: { day: date, slotId: _id ,cityId:selectedCity} });
    };
    const [slots, setSlots] = useState([]);

    const [selectedCity, setSelectedCity] = useState(1);


    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await axios.post(`${baseurl}/${slotEndpoint}`, { cityId: selectedCity });
                setSlots(response.data.data);
            } catch (error) {
                console.error('Error fetching data:', error);
            }
        };

        fetchData();
    }, [selectedCity]);
   
    const formatDate = (dateString) => {
        return moment(dateString).format('DD-MM-YYYY');
    }
    const handleCityChange = (cityId) => {
        localStorage.setItem('selectedCity', cityId);
        setSelectedCity(cityId);
    };

    return (
        <>
            <div className="container-fluid slot" style={{ paddingTop: '10rem', paddingBottom: '8rem' }}>
                <div className="row d-flex justify-content-center">

                    <div className="col-6 slot-dates p-5">
                        <div className="row d-flex justify-content-center">
                           <div className="row d-flex justify-content-center">
                           <div className="col-6 text-right">
                                {/* <h3 className="">Select city:</h3> */}

                            </div>
                            <div className="col-6 text-end">
                                <div className="">
                                    <Dropdown>
                                        <Dropdown.Toggle variant="warning" id="dropdown-basic">
                                            Select City
                                        </Dropdown.Toggle>
                                        <Dropdown.Menu>
                                            <Dropdown.Item onClick={() => handleCityChange(2)}>Gandhinagar</Dropdown.Item>
                                            <Dropdown.Item onClick={() => handleCityChange(1)}>Ahmedabad</Dropdown.Item>
                                        </Dropdown.Menu>
                                    </Dropdown>
                                </div>
                            </div>
                           </div>

                            <h1 className="headings slot-head mb-5 mt-5">Select date</h1>

                            {slots.map((slot, index) => (
                                slot.status ? (
                                    <div
                                        key={index}
                                        className={`col-sm-6 col-md-6 col-lg-4 m-2 slotBox`}
                                        onClick={() => handlePageOpen(slot.date, slot._id)}
                                    >
                                        {formatDate(slot.date)}
                                    </div>
                                ) : null
                            ))}
                        </div>
                    </div>
                </div>

            </div>
        
        </>
    )
};

export default Slot;

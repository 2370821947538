import React from "react";
import { Outlet, Navigate } from "react-router-dom";
import auth from "../utils/auth";
import Header from '../components/header'


const ProtectedRoutes = () => { 
  const authToken = auth(); 
  const isAuthenticated = authToken && authToken !== "";

  return (
    <div>
      {isAuthenticated ? (
        <>
        <Header/>
          <Outlet />
          
        </>
      ) : ( 
        <Navigate to="/" />
      )}
    </div>
  );
};
export default ProtectedRoutes;

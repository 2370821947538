import React, {useState } from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';

import Selectpage from './components/pages/selectPage';
import Page1 from './components/pages/page1';
import Page2 from './components/pages/page2';
import Page3 from './components/pages/page3';
import Page4 from './components/pages/page4';
import Slot from './components/slot';
import ProtectedRoutes from './authPage/protectedroute';
import Login from './authPage/login'

function App() {
  const [loading, setLoading] = useState(true);


  return (
    <Router>
        <Routes>
          <Route element={<ProtectedRoutes />}>
            {/* Protected routes */}
            <Route path="/select-page" element={<Selectpage />} />
            <Route path="/Page1" element={<Page1 />} />
            <Route path="/Page2" element={<Page2 />} />
            <Route path="/Page3" element={<Page3 />} />
            <Route path="/Page4" element={<Page4 />} />
            <Route path="/Slot" element={<Slot />} />
          </Route>
          <Route path="/" element={<Login />} /> {/* Login route */}
        </Routes>
    </Router>
  );
}

export default App;

import React, { useState, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import header from '../../images/logoImage.png';
import gnvHeader from '../../images/gandhinagarLogo.png'
import Scan from '../../images/scan.jpg';
import axios from 'axios';
import Swal from 'sweetalert2';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
const moment = require('moment');

const baseurl = process.env.REACT_APP_BASE_URL;
const adsEndpoint = process.env.REACT_APP_FIND_ADS_ENDPOINT;
const bookedAdsEndpoint = process.env.REACT_APP_BOOKED_ADS_ENDPOINT;
const clientEndpoint = process.env.REACT_APP_CREATECLIENT_ENDPOINT;
const paymentEndpoint = process.env.REACT_APP_ADS_PAYMENT_ENDPOINT;
const payment_key = process.env.REACT_APP_KEY_ID;
const createPayment_Endpoint = process.env.REACT_APP_CREATE_PAYMENT_ENDPOINT;
const paymentStatus_Endpoint = process.env.REACT_APP_UPDATE_PAYMENT_STATUS_ENDPOINT;
const uploadimageEndpoint = process.env.REACT_APP_BOOKED_ADS_UPLOAD_IMG_ENDPOINT
const emailEndpoint = process.env.REACT_APP_ADS_EMAIL_ENDPOINT;
const imageurl = "https://qgbimages.s3.ap-south-1.amazonaws.com/AdsImage";

const cityId = localStorage.getItem('selectedCity');

const Page2 = () => {
    const navigate = useNavigate()
    const Location = useLocation();
    const [orderId, setOrderId] = useState('');
    const selectedDate = Location.state && Location.state.selectedDate;
    const slotId = Location.state && Location.state.slotId;
    const formattedDate = selectedDate ? moment(selectedDate).format('YYYY-MM-DD') : null;
    const formattedDateForPage = selectedDate ? moment(selectedDate).format('DD-MM-YYYY') : null;

    if (formattedDate == null) {
        navigate('/admin/BookedAds')
    }
    useEffect(() => {
        const script = document.createElement('script');
        script.src = "https://checkout.razorpay.com/v1/checkout.js";
        script.async = true;
        document.head.appendChild(script);

        return () => {
            document.head.removeChild(script);
        };
    }, []);

    const [open_add_slotModal, setopen_add_slotModal] = useState(false);
    const [selectedSlotId, setSelectedSlotId] = useState(null);
    const [selectedImage, setselectedImage] = useState(null);
    const [error, setError] = useState('');
    const [show, setShow] = useState(false);
    const [name, setName] = useState('')
    const [email, setEmail] = useState('')
    const [mobile, setMobile] = useState('')
    const [GST_No, setGST_No] = useState('')
    const [paymentMode, setPaymentMode] = useState('offline');
    const [location, setLocation] = useState('')

    const [nameError, setNameError] = useState('');
    const [emailError, setEmailError] = useState('');
    const [mobileError, setMobileError] = useState('');
    const [locationError, setLocationError] = useState('');



    const [data, setData] = useState([]);
    const [selectedAdId, setSelectedAdId] = useState(null);
    const [clientName, setClientName] = useState('');



    const handleUploadChange = (e) => {

        setselectedImage(e.target.files[0]);
        setError('');
    };

    const handleUploadImage = async (e) => {
        e.preventDefault();
        try {
            const formData = new FormData();
            formData.append('image', selectedImage);
            formData.append('_id', selectedSlotId);
            console.log(selectedSlotId)
            const response = await axios.put(`${baseurl}/${uploadimageEndpoint}`, formData, {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            });
            if (response.data.status == true) {
                Swal.fire({
                    icon: 'success',
                    title: 'Success',
                    text: 'image upload successful',
                    // showCancelButton: true,
                    confirmButtonText: 'ok',
                }).then((result) => {
                    if (result.isConfirmed) {
                        window.location.reload();
                    }
                });
            }
        } catch (error) {
            console.error('Error uploading image:', error);

        }
    };

    const handleKeyDown = (e) => {
        if (!((e.keyCode > 95 && e.keyCode < 106) || // Numpad keys
            (e.keyCode > 47 && e.keyCode < 58) ||  // Number keys
            e.keyCode === 8 || e.keyCode === 9 ||  // Backspace and Tab
            e.keyCode === 37 || e.keyCode === 39 || // Left and Right arrow keys
            e.keyCode === 46                         // Delete key
        )) {
            e.preventDefault();
        }
    };
    useEffect(() => {
        const fetchData = async () => {
            try {

                const data = {
                    page_id: "65eeda24e1c47776ec8ce284",
                    slot_Date: formattedDate,

                }
                const url = `${baseurl}/${adsEndpoint}`;
                const response = await axios.post(url, data)
                setData(response.data.data);
            } catch (error) {
                console.error('Error fetching data:', error);
            }
        };

        fetchData();
    }, []);

    const handleClose = () => setShow(false);
    const closeModal = () => {
        setopen_add_slotModal(false);
    };
    const openModal = (booked_id) => {
        setSelectedSlotId(booked_id)
        setopen_add_slotModal(true);
    };


    const generateAdsComponents = () => {
        return data.map((ad, index) => (
            <>
                {ad.image ? (
                    <>
                        <img src={`${imageurl}/${ad.image}`} width="100%" alt="Ads" className={`col-3 text-center m-1 ads${index + 1}_For_page2 adsImage`} />
                    </>
                ) : (
                    <>
                        <div key={index} className={`col-3 text-center m-1 ads${index + 1}_For_page2 ${ad.Is_booked ? "bg-danger" : "notBooked"}`}>
                            {ad.image ? (
                                <></>
                            ) : (
                                <>
                                    <p>PageSize:- {ad.pageSize}</p>
                                    <p>unique_id:- {ad.unique_id}</p>
                                    <p>Price:- {ad.price}</p>
                                    {ad.client_id && <p>client_id:- {ad.client_id.name}</p>}
                                    {ad.Is_booked && <p><b>This ad is booked.</b></p>}
                                    {ad.Is_booked && <button className="allpage_book_btn" onClick={() => openModal(ad.booked_id)}>upload Img</button>}
                                </>
                            )}
                        </div>
                    </>
                )}
            </>
        

        ));
    };
    return (
        <div className="container pt-5" style={{ paddingBottom: '8rem' }}>
            <h2 className="headings text-center mb-5">Selected Date:{formattedDateForPage}</h2>
            <div className="row d-flex justify-content-center ">
                <div className="col-md-12 col-sm-12 col-lg-6 col-xl-6 col-12 page2">
                    <div className="row d-flex justify-content-center align-items-center p-2">
                        <div className="row d-flex justify-content-center align-items-center p-2" style={{ background: '#f8f9fb', borderRadius: '10px' }}>
                            <div className="col-4 bg-light pageDate"> <h5>{formattedDateForPage}</h5></div>
                            {cityId == "1" &&(
                            <div className="col-6 bg-light">  <img src={header} className="logo-for-page" /></div>

                            )}
                              {cityId =="2" &&(
                            <div className="col-6 bg-light">  <img src={gnvHeader} className="logo-for-page" /></div>

                            )}
                            <div className="col-2 bg-light pageScan" >  <img src={Scan} className="logo-for-page" /></div>
                        </div>
                        {generateAdsComponents()}
                    </div>
                    {/* </div> */}
                </div>
            </div>
          

            <Modal show={open_add_slotModal} onHide={closeModal} dialogClassName="modal-responsive">
                <Modal.Header closeButton>
                    <Modal.Title>Add Slot</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <form onSubmit={handleUploadImage}>
                        <div className="form-group">
                            <label>upload file:</label>
                            <input
                                type="file"
                                className="form"
                                onChange={handleUploadChange}
                            />
                        </div>
                        {error && <div className="alert alert-danger">{error}</div>}
                        <button type="submit" className="btn btn-primary bg-primary" onClick={handleUploadImage}>Submit</button>
                    </form>
                </Modal.Body>
            </Modal>
        </div>

    );
}

export default Page2;

